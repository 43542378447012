import { episodes as E } from '../definitions';

const initialState = {
    result: [],
    loading: false,
    error: {}
};

const episodesReducer =  (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case E.GET_EPISODES_REQUESTED:
        return {
            ...payload,
            loading: true,
            error: {}
        };
    case E.GET_EPISODES_SUCCESS:
        return {
            ...payload,
            loading: false,
            error: {}
        }
    case E.GET_EPISODES_FAILED:
        return {
            ...state,
            loading: false,
            error: payload
        }
    default:
      return state;
  }
};

export { episodesReducer };