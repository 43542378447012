import { combineReducers } from 'redux';
import { episodeReducer as episode } from  './episode-reducer';
import { episodesReducer as episodes } from  './episodes-reducer';
import { showsReducer as shows } from  './shows-reducer';
import { showReducer as show } from  './show-reducer';
import { principalsShowsReducer as principals_shows } from './prinicipal-shows-reducer';
import { programmingReducer as programming } from './programming-reducer';

const appReducer = combineReducers({
    episode,
    episodes,
    shows,
    show,
    principals_shows,
    programming,
});

export default appReducer;
