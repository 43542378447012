import { put, takeLatest, call } from 'redux-saga/effects';
import { programming as P } from "../definitions";
import { getProgramming } from '../../services/Api';

function* getProgrammingSagas({ payload }) {
  try {
    const response = yield call(getProgramming, payload);
    yield put({
      type: P.GET_PROGRAMMING_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: P.GET_PROGRAMMING_FAILED,
      payload: error,
    });
  }
}



const programmingSagas = [
  takeLatest(P.GET_PROGRAMMING_REQUESTED, getProgrammingSagas),
];

export { programmingSagas };
