export const shows = {
    GET_ALL_SHOWS_REQUESTED: 'GET_ALL_SHOWS_REQUESTED',
    GET_ALL_SHOWS_SUCCESS: 'GET_ALL_SHOWS_SUCCESS',
    GET_ALL_SHOWS_FAILED: 'GET_ALL_SHOWS_FAILED',
    GET_ALL_EPISODES_BY_SHOW_REQUESTED: 'GET_ALL_EPISODES_BY_SHOW_REQUESTED',
    GET_ALL_EPISODES_BY_SHOW_SUCCESS: 'GET_ALL_EPISODES_BY_SHOW_SUCCESS',
    GET_ALL_EPISODES_BY_SHOW_FAILED: 'GET_ALL_EPISODES_BY_SHOW_FAILED',
    GET_PRINCIPALS_SHOWS_REQUESTED: 'GET_PRINCIPALS_SHOWS_REQUESTED',
    GET_PRINCIPALS_SHOWS_SUCCESS: 'GET_PRINCIPALS_SHOWS_SUCCESS',
    GET_PRINCIPALS_SHOWS_FAILED: 'GET_PRINCIPALS_SHOWS_FAILED',
};
  