import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { getAllEpisodesByShow, getShow } from '../../redux/actions';
import { Card } from '../../components/Card';
import './ShowDetail.scss';

const ShowDetail = () => {
  
  const show = useSelector(state => state.show);

  const dispatch = useDispatch()
  let params = useParams();
  
  useEffect(()=>{
    const showIdParam = parseInt(params.show_id)
    if(show.id !== showIdParam){
      dispatch(getShow(params.show_id))
    }
  },[])

  const renderHeader = () =>{
    return(
      <div style={{ 
        backgroundImage: `url(${show?.banner_background_crop})`,
        backgroundColor: 'white',
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: "50vh",
      }}>
        
      </div>
    )
  }

  const renderEpisodes = () => {
    return (
      show.episodes.results.map((episode, index) => {
      const subtitle = `Temporada: ${episode.season_number} · Episodio: ${episode.episode_number}`;
      return (
          <div key={episode.id} className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3">
            <Link to={`./episodio/${episode.id}/`} className="link-show-detail">
              <Card 
                key={episode.id}
                image={episode.thumbnail_crop}
                title={episode.title} 
                subtitle={subtitle} 
                description={episode.description} 
                thumbnail={show.thumbnail_crop}
              />
            </Link>
          </div>
      )})
    )
  }

  const loadMoreEpisodes = () => {
    if(show.episodes.next){
      const queryString = show.episodes.next.split('?')[1]
      const urlParams = new URLSearchParams(queryString);
      const limit = urlParams.get('limit')
      const offset = urlParams.get('offset')
      dispatch(getAllEpisodesByShow({show_id: params.show_id, limit, offset}))
    }
  }

  const renderLoading = ({ text }) => {
    return(
      <div style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 40,
      }}
      >
        <FontAwesomeIcon icon={faSpinner} className="fa-spin fa-4x"/>
        <p>{text}</p>
      </div>
    )
  }

  const renderBody = () => {
    return (
      <div className="container mt-4 mb-4">
        <div className="row">
          <div className="col">
            <h4>Episodios</h4>
          </div>
        </div>
        <div className='row'>
          {renderEpisodes()}
        </div>
        <div className='row mt-4'>
          <div className="col">
                <div className="d-grid gap-2">
                  {show.episodes.results.length < show.episodes.count && 
                    <button className="btn btn-light card-title text-center more-shows-button p-3" disabled={show.episodes.loading} type="button" onClick={loadMoreEpisodes}>
                    {show.episodes.loading ? <FontAwesomeIcon icon={faSpinner} className="fa-spin"/> : "Ver más episodios..." }
                  </button>
                  }
                </div>
              </div>
          </div>
      </div>
    )
  }

  const renderPage = () => {
    if(show.loading){
      return renderLoading("Cargando...");
    }else{
      return (
        <div>
          {renderHeader()}
          {(show.episodes.results.length === 0 && !show.episodes.loading) ? renderLoading("Cargando Episodios...") : renderBody()}
        </div>
      )
    }
  }

  return (
    <div style={{minHeight: '100vh'}}>
      {renderPage()}
    </div>
  )
}



export { ShowDetail }