import React from 'react';
import './DisplayIframe.scss'

const DisplayIframe = ({ children }) => {
  return (
    <div className="container player-container">
      <div className="row">
        <div className="col">
          <div className='embed-responsive'>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export { DisplayIframe }