import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import './Card.scss';

const Card = ({image, title, subtitle, description, thumbnail, width, height = "100%", type}) => {
  return (
    <div className="card" style={{width: width, height: height, border: 0}}>
        {image && <img src={image} className="card-img-top" alt={ title || image } />}
        {(!image && thumbnail) && <img src={thumbnail} className="card-img-top" alt={ title || image } />}
        {(title || subtitle || description) &&
          <div className="card-body">
            {type ==="latest" ? <h6 className="card-title">{title}</h6> : title && <h5 className="card-title">{title}</h5>}
            {subtitle && <h6 className="card-subtitle mb-2 text-muted">{subtitle}</h6>}
            {description && 
              <LinesEllipsis
                text={description}
                maxLine={3}
                ellipsis='...'
              />
            }
          </div>
        }
    </div>
  )
}

export { Card }