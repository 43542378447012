import { createBrowserRouter } from "react-router-dom";
import { Home } from "../pages/Home";
import { Shows } from "../pages/Shows";
import { ShowDetail } from "../pages/ShowDetail";
import { Episode } from "../pages/Episode";
import { Programming } from '../pages/Programming';
import PublicLayout from '../layouts/PublicLayout';

export const router = createBrowserRouter([{
    path: "/",
    // errorElement: <NotFound />,
    element: <PublicLayout />,
    children: [{
        index: true,
        element: <Home />,
    },
    {
        path: "/programas",
        element: <Shows />,
    },
    {
        path: "/programa/:show_id/",
        children: [{
            index: true,
            element: <ShowDetail />,
        },
        {
            path: "episodio/:episode_id/",
            element: <Episode />,
        }]
    },
    {
        path: "/programacion/",
        element: <Programming />,
    }]
}]);