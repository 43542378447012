import React from 'react';
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons"
import './NavBar.scss';

const NavBar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img alt="Logo" src="/assets/images/MiRadioTv_black.png"/>
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#myNavbar" aria-controls="myNavbar" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse flex-grow-1 text-right" id="myNavbar">
          <ul className="navbar-nav ms-auto flex-nowrap" style={{alignItems: 'flex-end'}}>
              <li className="nav-item">
                <NavLink aria-current="page" className="nav-link" to="/">Inicio</NavLink>
              </li>
              <li className="nav-item">
                <NavLink aria-current="page" className="nav-link" to="/programas">Programas</NavLink>
              </li>
              <li className="nav-item">
                <NavLink aria-current="page" className="nav-link" to="/programacion">Programación</NavLink>
              </li>
              <li className="nav-item">
                <a aria-current="page" className="nav-link" href="https://www.miradiols.cl/">miradiols.cl</a>
              </li>
              <li className="nav-item">
                <a aria-current="page" className="nav-link" href="https://www.facebook.com/MiRadioLs/">
                  <FontAwesomeIcon icon={faFacebook} className='fa-2x' />
                </a>
              </li>
              <li className="nav-item">
                <a aria-current="page" className="nav-link" href="https://twitter.com/miradiols/">
                  <FontAwesomeIcon icon={faTwitter} className='fa-2x' />
                </a>
              </li>
              <li className="nav-item">
                <a aria-current="page" className="nav-link" href="https://www.instagram.com/miradiols/?hl=es">
                  <FontAwesomeIcon icon={faInstagram} className='fa-2x' />
                </a>
              </li>
              <li className="nav-item">
                <a aria-current="page" className="nav-link" href="https://www.tiktok.com/@miradiols">
                  <FontAwesomeIcon icon={faTiktok} className='fa-2x' />
                </a>
              </li>
          </ul>
      </div>
    </div>
    </nav>
  )
}

export default NavBar