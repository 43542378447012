import React from 'react';
import './LiveStyle.css';
import { DisplayIframe } from '../DisplayIframe';

const Live = () => {
  return (
    <DisplayIframe>
      <div className='gif-en-vivo'>
        <img alt="En vivo" src="assets/images/TRANSMISION-EN-VIVO.gif" width="200"/>
      </div>
      <iframe title="live" allowfullscreen="" frameborder="0" width="640" height="360" name="gzplayer" scrolling="no" src="//live.grupoz.cl/7228e98d389d9d54ca6d5ab974f2ea15"></iframe>
    </DisplayIframe>
  )
}

export default Live