import React from 'react'

const Footer = () => {
  return (
    <>
        <section style={{backgroundColor: "#000"}}>
            <div className="container" style={{color: "#fff"}}>
                <div className="row text-center ">
                    <div className="col-12 pt-5 pb-5" style={{fontSize: 18}}>
                        <p className="smaller">© 2017 - 2023 Mi RadioLS</p>
                        <p>Desarrollado por <a href="https://www.miradiols.cl/" style={{color: 'white'}} target="_blank" rel="noreferrer">Mi Radio LS™️</a></p>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Footer