import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getProgramming } from '../../redux/actions';
import './Programming.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

class Programming extends Component {

  componentDidMount(){
    const { getProgramming } = this.props;
    getProgramming();
  }
  
  programming(){
    const { programmingResult } = this.props;
    return (
      <div>
        <div className="pb-5">
          <div className="container text-center">
            {programmingResult?.map((show, index)=>(
              <div key={index} className="row">
                <div className="col-12 pt-4 pb-4">
                  <img className="img-fluid" src={show.image_thumb} alt=""/>
                </div>
              </div>
              ))}
          </div>
        </div>
      </div>
    )
  }

  loading(){
    return (
        <div>
            <div className='container-loading'>
                <p className="text-center">
                <FontAwesomeIcon icon={faSpinner} className="fa-spin"/><br />
                Cargando...
                </p>
            </div>
        </div>
    )
  }
  
  
  render(){
    const { programmingLoading } = this.props;
    return (
      <div>
        { programmingLoading? this.loading() : this.programming() }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  programmingLoading: state.programming.loading,
  programmingResult: state.programming.results,
});

const mapDispatchToProps = {
    getProgramming,
};

const connectProgramming = connect(mapStateToProps, mapDispatchToProps)(Programming);

export { connectProgramming as Programming }