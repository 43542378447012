import { put, takeLatest, call } from 'redux-saga/effects';
import { episodes as E } from "../definitions";
import { getEpisodesByShow, getEpisode, getEpisodes } from '../../services/Api';

function* getEpisodesByShowSagas({ payload }) {
  try {
    const response = yield call(getEpisodesByShow, payload);
    yield put({
      type: E.GET_ALL_EPISODES_BY_SHOW_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: E.GET_ALL_EPISODES_BY_SHOW_FAILED,
      payload: error,
    });
  }
}

function* getEpisodeSagas({ payload }) {
  try {
    const response = yield call(getEpisode, payload);
    yield put({
      type: E.GET_EPISODE_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: E.GET_EPISODE_FAILED,
      payload: error,
    });
  }
}

function* getEpisodesSagas({ payload }) {
  try {
    const response = yield call(getEpisodes, payload);
    yield put({
      type: E.GET_EPISODES_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: E.GET_EPISODES_FAILED,
      payload: error,
    });
  }
}

const episodesSagas = [
  takeLatest(E.GET_ALL_EPISODES_BY_SHOW_REQUESTED, getEpisodesByShowSagas),
  takeLatest(E.GET_EPISODES_REQUESTED, getEpisodesSagas),
  takeLatest(E.GET_EPISODE_REQUESTED, getEpisodeSagas)
];

export { episodesSagas };
