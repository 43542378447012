// export const URL_API = 'http://localhost:8000/api'
export const URL_API = 'https://admin.miradiotv.cl/api'

export const getEpisodesByShow = payload => {
  
  const params = (payload.limit && payload.offset) ? `?limit=${payload.limit}&offset=${payload.offset}`:''
  const GET_EPISODES_BY_SHOW_API_ENDPOINT = `${URL_API}/episodes-by-show/${payload.show_id}/${params}`;

  const parameters = {
    method: 'GET',
  };

  return fetch(GET_EPISODES_BY_SHOW_API_ENDPOINT, parameters)
    .then(response => response.json())
    .then(json => json);
};

export const getShows = payload => {
  
  const GET_SHOWS_API_ENDPOINT = `${URL_API}/shows/${payload ? payload : ''}`;

  const parameters = {
    method: 'GET',
  };

  return fetch(GET_SHOWS_API_ENDPOINT, parameters)
    .then(response => response.json())
    .then(json => json);
};

export const getPrincipalShows = payload => {
  
  const GET_PRINCIPAL_SHOWS_API_ENDPOINT = `${URL_API}/principals-shows/`;

  const parameters = {
    method: 'GET',
  };

  return fetch(GET_PRINCIPAL_SHOWS_API_ENDPOINT, parameters)
    .then(response => response.json())
    .then(json => json);
};

export const getEpisode = payload => {
  
  const GET_EPISODE_API_ENDPOINT = `${URL_API}/episodes/${payload}`;

  const parameters = {
    method: 'GET',
  };

  return fetch(GET_EPISODE_API_ENDPOINT, parameters)
    .then(response => response.json())
    .then(json => json);
};

export const getEpisodes = payload => {
  
  const GET_EPISODES_API_ENDPOINT = `${URL_API}/episodes/?limit=12`;

  const parameters = {
    method: 'GET',
  };

  return fetch(GET_EPISODES_API_ENDPOINT, parameters)
    .then(response => response.json())
    .then(json => json);
};

export const getProgramming = payload => {
  
  const GET_PROGRAMMING_API_ENDPOINT = `${URL_API}/programming/`;

  const parameters = {
    method: 'GET',
  };

  return fetch(GET_PROGRAMMING_API_ENDPOINT, parameters)
    .then(response => response.json())
    .then(json => json);
};