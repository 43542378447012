import { all } from 'redux-saga/effects';
import { episodesSagas } from './episodes-sagas';
import { showsSagas } from './shows-sagas';
import { programmingSagas } from './programming-sagas';

export default function* rootSaga() {
  yield all([
    ...episodesSagas,
    ...showsSagas,
    ...programmingSagas,
  ]);
}
