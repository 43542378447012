import { episodes as E } from '../definitions'

const initialState = {
    result: {},
    loading: false,
    error: {}
};

const episodeReducer =  (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case E.GET_EPISODE_REQUESTED:
        return {
            result: {},
            loading: true,
            error: {}
        };
    case E.GET_EPISODE_SUCCESS:
        return {
            result: payload,
            loading: false,
            error: {}
        }
    case E.GET_EPISODE_FAILED:
        return {
            ...state,
            loading: false,
            error: payload
        }
    default:
      return state;
  }
};

export { episodeReducer };