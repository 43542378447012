import { shows as S, show as SHOW } from '../definitions';

export const getAllShows = payload => {
  return {
    type: S.GET_ALL_SHOWS_REQUESTED,
    payload,
  };
};


export const getPrinicipalsShows = payload => {
  return {
    type: S.GET_PRINCIPALS_SHOWS_REQUESTED,
    payload,
  };
};
export const getShow = payload => {
  return {
    type: SHOW.GET_SHOW_REQUESTED,
    payload,
  };
};