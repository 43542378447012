import { shows as S } from '../definitions'

const initialState = {
    count: 0,
    next: null,
    previous : null,
    results: [],
    loading: false,
    error: {}
};

const principalsShowsReducer =  (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case S.GET_PRINCIPALS_SHOWS_REQUESTED:
        return {
            ...state,
            loading: true,
            error: {}
        };
    case S.GET_PRINCIPALS_SHOWS_SUCCESS:
        return {
            ...payload,
            loading: false,
            error: {}
        }
    case S.GET_PRINCIPALS_SHOWS_FAILED:
        return {
            ...state,
            loading: false,
            error: payload
        }
    default:
      return state;
  }
};

export { principalsShowsReducer };