import { show as S, episodes as E } from '../definitions'

const initialState = {
    id: null,
    title: null,
    description: null,
    banner_background: null,
    categories: [],
    genre: null,
    number_of_seasons: null,
    priority: null,
    release_date: null,
    thumbnail: null,
    episodes: {
        count: 0,
        next: null,
        previous : null,
        results: [],
        loading: false,
        error: {}
    },
    loading: false,
    error: {}
};

const showReducer =  (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case S.GET_SHOW_REQUESTED:
        return {
            ...state,
            loading: true,
            error: {}
        };
    case S.GET_SHOW_SUCCESS:
        return {
            ...payload,
            episodes: initialState.episodes,
            loading: false,
            error: {}
        }
    case S.GET_SHOW_FAILED:
        return {
            ...state,
            loading: false,
            error: payload
        }
    // EPISODIOS POR SHOW
    case E.GET_ALL_EPISODES_BY_SHOW_REQUESTED:
        return {
            ...state,
            episodes:{
                ...state.episodes,
                loading: true,
                error: {}
            }
        }
    case E.GET_ALL_EPISODES_BY_SHOW_SUCCESS:
        const results = [ ...state.episodes.results, ...payload.results];
        return {
            ...state,
            episodes:{
                ...payload,
                results: results,
                loading: false,
                error: {}
            }
        }
    case E.GET_ALL_EPISODES_BY_SHOW_FAILED:
        return {
            ...state,
            episodes:{
                ...state.episodes,
                loading: false,
                error: payload
            }
        }
    default:
      return state;
  }
};

export { showReducer };