import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import reducers from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = composeWithDevTools(
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
  applyMiddleware(sagaMiddleware /*, logger*/)
);
// create the saga middleware

// export default createStore(reducers, composeEnhancers(applyMiddleware(sagaMiddleware)));
export default createStore(reducers, composeEnhancers);
// then run the saga// then run the saga
sagaMiddleware.run(rootSaga);
