import { episodes as E } from '../definitions';

export const getAllEpisodesByShow = payload => {
  return {
    type: E.GET_ALL_EPISODES_BY_SHOW_REQUESTED,
    payload,
  };
};

export const getEpisode = payload => {
  return {
    type: E.GET_EPISODE_REQUESTED,
    payload,
  };
};

export const getEpisodes = payload => {
  return {
    type: E.GET_EPISODES_REQUESTED,
    payload,
  };
};