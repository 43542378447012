import React, { Component } from 'react';
import Live from '../../components/Live/Live';
import './Home.scss';
import { getPrinicipalsShows, getEpisodes } from '../../redux/actions';
import { connect } from 'react-redux';
import Slider from '../../components/Slider/Slider';
import { Link } from 'react-router-dom';
import { Card } from '../../components/Card';
class Home extends Component {

  componentDidMount(){
    const { getPrinicipalsShows, getEpisodes } = this.props;
    getPrinicipalsShows();
    getEpisodes()
  }

  header(){
    return (
      <section id="live">
        <div className='container-header'>
          <div className='live-container'>
            <div className='live'>
                <div className="container">
                  <Live />
                </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

  bannerShows(){
    const { showsResult } = this.props;
    return <section id="bannerShows" className="d-flex pb-2">
      <Slider data={ showsResult } itemsShown={1.2} itemsShownSm={3.3} itemsShownMd={4.4} itemsShownLg={5.5}/>
    </section>
  }

  recentEpisodes(){
    const { episodesResult } = this.props;
    return (
    <div className="container-fluid bg-dark pt-5 pb-5">
      <div className="container text-light">
        <h2>Capítulos anteriores</h2>
      </div>
      <section id="recentEpisodes" className="container">
        <div className="row">
          {episodesResult?.map((episode, index)=>{
            return(
              <div className="col-12 col-sm-6 col-md-4 mb-4">
                <div className="card" style={{width: "100%", height: "100%"}}>
                  <Link to={`/programa/${episode.show.id}/episodio/${episode.id}/`} className="link-show-detail">
                    <Card 
                      key={episode.id}
                      image={episode.thumbnail_crop}
                      title={episode.title} 
                      subtitle={`Temporada: ${episode.season_number} · Episodio: ${episode.episode_number}`} 
                      description={episode.description} 
                      thumbnail={episode.show.thumbnail_crop}
                    />
                  </Link>
                </div>
              </div>
            )}
          )}
        </div>
      </section>
    </div>
    )
  }

  shows(){
    const { showsResult } = this.props;
    return (
      <div>
      {showsResult > 0 && showsResult.map((show, index)=>{
        return(
          <div className="container-fluid pt-5 pb-5">
            <div className="container">
              <h2>{show.title}</h2>
            </div>
            <section id="shows" className="d-flex">
              <Slider data={ show } itemsShown={1.2} itemsShownSm={3.3} itemsShownMd={4.4} itemsShownLg={5.5} showItemTitle/>
            </section>
          </div>
        )
        })
      }
      </div>
    )
  }

  footer() {
    return(
      <div></div>
    //   <section style={{backgroundColor: "#f5f5f5"}}>
    //     <div className="container" style={{color: "#000"}}>
    //       <div className="row pt-5 pb-5">
    //         <div className="col-12" style={{fontSize: 12}}>
    //           <p>Señales de Televisión.</p>
    //           <p>Movistar Sección Canales Regionales | Cable Color canal 29.3 | Televisión Digital Libre Recepción en La Serena y Coquimbo canal 49.3 | Mundo Cable canal 801</p>
    //           <p>
    //               <br />
    //               Señales de Radio.
    //           </p>
    //           <p>La Serena y Coquimbo 98.5 FM | Ovalle 102.3 FM | Tongoy 94.3 FM | Vicuña 95.3 FM | La Higuera 91.5 FM | Salamanca 91.1 FM | Los VIlos 90.1 FM | Illapel 99.3 FM | Combarbala 101.5 FM | Cuncumén 95.9 FM</p>
    //           <p>
    //               <br />
    //               Señales Digitales.
    //           </p>
    //           <p>Aplicación para Móviles: Mi Radio LS en App Store (iOS) y Play Store (Android) | Aplicación para Smartv: Mi Radio LS - Android TV en Play Store | Aplicación para Apple TV: Mi Radio LS en App Store | Señal TV online Web Mi Radio LS: https://www.miradiols.cl/mi-radio-tv | Señal Radio online Web Mi Radio LS: https://www.miradiols.cl/senal-online</p>
    //           <p>
    //               <br />
    //               Infórmate en <a href="https://www.miradiols.cl/" style={{textDecoration: 'none', color: '#c0ec47'}} target="_blank" rel="noreferrer">www.miradiols.cl</a>
    //           </p>
    //         </div>
    //       </div>
    //     </div>
    // </section>
    )
  }

  render(){
    return (
      <div>
        { this.header() }
        { this.bannerShows() }
        <img alt="banner" src="/assets/images/banner_donde_vernos.png" className='img-fluid'/>
        { this.recentEpisodes() }
        { this.shows() }
        { this.footer() }
        
      </div>
    )
  }
}

const mapStateToProps = state => ({
  showsLoading: state.principals_shows.loading,
  showsResult: state.principals_shows.results,
  episodesResult: state.episodes.results,
});

const mapDispatchToProps = {
  getPrinicipalsShows,
  getEpisodes,
};

const connectHome = connect(mapStateToProps, mapDispatchToProps)(Home);

export { connectHome as Home }