import React, { useRef } from 'react';
import { SliderItem } from './SliderItem';
import { useContainerDimensions } from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import './Slider.scss';

const Slider = ({ data, itemsShown, itemsShownSm, itemsShownMd, itemsShownLg, showItemTitle }) => {
  
  gsap.registerPlugin(ScrollToPlugin);
  
  const componentRef = useRef()
  const { width } = useContainerDimensions(componentRef)

  const componentShown = () => {
    if (width > 0 && width < 695){
      return itemsShown
    }else if(width >= 696 && width < 935){
      return itemsShownSm
    }else if(width >= 936 && width < 1295){
      return itemsShownMd
    }else if(width >= 1296){
      return itemsShownLg
    }else{
      return itemsShown
    }
  }

  const itemWidth = width / componentShown()

  return (
    <div className="d-flex container mt-2 container-slider">
      <div id="slider" ref={componentRef} className="d-flex">
        {data?.map((item, index) =>
            {
              item.type = "latest"
              return <SliderItem key={index} item={item} width={itemWidth} showItemTitle={showItemTitle} />
            }
        )}
      </div>
        <button className="arrow-slider arrow-left btn btn-primary d-none d-sm-block" 
          onClick={() => 
          {
            gsap.to(componentRef.current, {duration: 2, scrollTo: {x: -itemWidth, offsetX: -componentRef.current.scrollLeft}, ease: "power2"});
          }
          }>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <button className="arrow-slider arrow-right btn btn-primary d-none d-sm-block" 
          onClick={() => {
            gsap.to(componentRef.current, {duration: 2, scrollTo: {x: itemWidth, offsetX: -componentRef.current.scrollLeft}, ease: "power2"});
          }}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
    </div>
  )
}

export default Slider