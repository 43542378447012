import { programming as P } from '../definitions'

const initialState = {
    result: {},
    loading: false,
    error: {},
    count: 0,
    next: null,
    previous: null,
};

const programmingReducer =  (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case P.GET_PROGRAMMING_REQUESTED:
        return {
            result: {},
            loading: true,
            error: {}
        };
    case P.GET_PROGRAMMING_SUCCESS:
        return {
            ...payload,
            loading: false,
            error: {}
        }
    case P.GET_PROGRAMMING_FAILED:
        return {
            ...state,
            loading: false,
            error: payload
        }
    default:
      return state;
  }
};

export { programmingReducer };