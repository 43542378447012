import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router";
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { getEpisode } from '../../redux/actions';
import { DisplayIframe } from '../../components/DisplayIframe/DisplayIframe';
import './Episode.scss';

const Episode = () => {

  const episode = useSelector(state => state.episode.result);

  const dispatch = useDispatch()
  let params = useParams();
  const showIdParam = parseInt(params.show_id)

  const location = useLocation()

  useEffect(() => {
    const episodeIdParam = parseInt(params.episode_id)
    if(episode.id !== episodeIdParam){
      dispatch(getEpisode(params.episode_id))
    }
  }, [location.key])

  return (
    <div className="container">
      <div className="row p-5">
        <div className="col-12 col-lg-9 align-self-end">
          <DisplayIframe>
            <iframe 
              class="rumble" 
              width="640" 
              height="360" 
              src={episode.path} 
              frameborder="0" 
              allowfullscreen></iframe>
          </DisplayIframe>
        </div>
        <div className="d-flex col-12 col-lg-3" style={{ flex: 1, flexDirection: 'column'}}>
          <div className="row">
            <div className='col-12 mt-4 mt-lg-0'>
              <h4>{episode.title}</h4>
              <p>{episode.description}</p>
            </div>
          </div>
          <div className='d-flex' style={{flex: 1, flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
            {episode.next && 
              <div class="col-12">
                <div class="card mb-2">
                  <div class="card-body text-center">
                    <Link 
                      to={`/programa/${params.show_id}/episodio/${episode.next}`} 
                      className="link-reference">
                        Episodio Siguiente &nbsp; <FontAwesomeIcon icon={faArrowRight} />
                    </Link>
                  </div>
                </div>
              </div>
            }
            {episode.previous && 
              <div class="col-12">
                <div class="card mb-2">
                  <div class="card-body text-center">
                    <Link 
                      to={`/programa/${params.show_id}/episodio/${episode.previous}`} 
                      className="link-reference">
                        <FontAwesomeIcon icon={faArrowLeft} /> &nbsp; Episodio Anterior
                    </Link>
                  </div>
                </div>
              </div>
            }
            <div class="col-12">
              <div class="card">
                <div class="card-body text-center">
                  <Link to={`/programa/${showIdParam}/`} className="link-reference">Todos los Episodios</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { Episode }