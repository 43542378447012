import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from '../../Card';
import './SliderItemStyle.scss';

const SliderItem = ({item, width, showItemTitle}) => {

	return (
		<div style={{width}} className="m-1">
			<Link to={`/programa/${item.id}`} className="link-card">
				<Card image={item?.thumbnail_crop}  width={width} title={showItemTitle ? item?.title : null} type={item.type} />
			</Link>
		</div>
			
	)
}

export { SliderItem }