export const episodes = {
    GET_ALL_EPISODES_BY_SHOW_REQUESTED: 'GET_ALL_EPISODES_BY_SHOW_REQUESTED',
    GET_ALL_EPISODES_BY_SHOW_SUCCESS: 'GET_ALL_EPISODES_BY_SHOW_SUCCESS',
    GET_ALL_EPISODES_BY_SHOW_FAILED: 'GET_ALL_EPISODES_BY_SHOW_FAILED',
    GET_EPISODES_REQUESTED: 'GET_EPISODES_REQUESTED',
    GET_EPISODES_SUCCESS: 'GET_EPISODES_SUCCESS',
    GET_EPISODES_FAILED: 'GET_EPISODES_FAILED',
    GET_EPISODE_REQUESTED: 'GET_EPISODE_REQUESTED',
    GET_EPISODE_SUCCESS: 'GET_EPISODE_SUCCESS',
    GET_EPISODE_FAILED: 'GET_EPISODE_FAILED',
};
  