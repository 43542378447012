import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import { getAllShows } from '../../redux/actions';
import './Shows.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

class Shows extends Component {

  componentDidMount(){
    const { getAllShows, showsResult, showsLoading } = this.props;
    if(showsResult?.length === 0 && !showsLoading){
      getAllShows();
    }
  }

  loadMoreShows = () => {
    const { getAllShows, showsNext } = this.props;
    if(showsNext){
      const queryString = showsNext.split('?')[1]
      const urlParams = new URLSearchParams(queryString);
      getAllShows(`?${urlParams}`);
    }
  }

  shows(){
    const { showsResult, showsLoading, showsCount } = this.props;
    return (
      <div className="container pt-5 pb-5">
        <div className='row'>
        {showsResult?.map((show, index)=>{
          const releaseDate = Moment(show.release_date).format('DD-MM-YYYY')
          return(
            <div className="col-sm-6 col-lg-4 mb-3">
              <Link key={show.id} to={`/programa/${show.id}/`} className="show-link">
                <div className="card" style={{width: "100%", height: "100%"}}>
                  <img src={show.thumbnail_crop} className="img-fluid rounded-start" alt={`imagen ${show.title}`} />
                  <div className="card-body">
                    <h5 className="card-title">{show.title}</h5>
                    <p className="card-text">{show.description}</p>
                    <div>
                      <p className="card-text mb-0"><small className="text-muted">Temporadas: {show.number_of_seasons}</small></p>
                      <p className="card-text"><small className="text-muted">Fecha de Lanzamiento: {releaseDate}</small></p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          )
        })}
        </div>
        <div class="row">
          <div className="col">
            <div className="d-grid gap-2">
              {showsResult.length < showsCount && 
                <button className="btn btn-light card-title text-center more-shows-button p-3" disabled={showsLoading} type="button" onClick={this.loadMoreShows}>
                {showsLoading ? <FontAwesomeIcon icon={faSpinner} className="fa-spin"/> : "Ver más programas..." }
              </button>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }


  render(){
    return (
      <div>
        { this.shows() }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  showsLoading: state.shows.loading,
  showsResult: state.shows.results,
  showsCount: state.shows.count,
  showsNext: state.shows.next,
  showsPrevious: state.shows.previous
});

const mapDispatchToProps = {
  getAllShows,
};

const connectShows = connect(mapStateToProps, mapDispatchToProps)(Shows);

export { connectShows as Shows }