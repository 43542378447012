import { put, takeLatest, call } from 'redux-saga/effects';
import { shows as S, show as SHOW, episodes as E } from "../definitions";
import { getShows, getPrincipalShows } from '../../services/Api';

function* getShowsSagas({ payload }) {
  try {
    const response = yield call(getShows, payload);
    yield put({
      type: S.GET_ALL_SHOWS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: S.GET_ALL_SHOWS_FAILED,
      payload: error,
    });
  }
}

function* getPrincipalShowsSagas({ payload }) {
  try {
    const response = yield call(getPrincipalShows, payload);
    yield put({
      type: S.GET_PRINCIPALS_SHOWS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: S.GET_PRINCIPALS_SHOWS_FAILED,
      payload: error,
    });
  }
}

function* getShowSagas({ payload }) {
  try {
    const response = yield call(getShows, payload);
    yield put({
      type: SHOW.GET_SHOW_SUCCESS,
      payload: response,
    });
    yield put({
      type: E.GET_ALL_EPISODES_BY_SHOW_REQUESTED,
      payload: {show_id: payload},
    });
  } catch (error) {
    yield put({
      type: SHOW.GET_SHOW_FAILED,
      payload: error,
    });
  }
}



const showsSagas = [
  takeLatest(S.GET_ALL_SHOWS_REQUESTED, getShowsSagas),
  takeLatest(S.GET_PRINCIPALS_SHOWS_REQUESTED, getPrincipalShowsSagas),
  takeLatest(SHOW.GET_SHOW_REQUESTED, getShowSagas),
];

export { showsSagas };
